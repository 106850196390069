import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

import Button from 'creative-components/CustomButtons/Button';

import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';
import { useDataFilterContext } from 'components/DataMap/DataFilterProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import CampaignLeadsTable from 'components/CampaignLeadsTable/CampaignLeadsTable';
import DashboardSection from 'components/DashboardSection/DashboardSection';
import DashboardBubble from 'components/DashboardBubble/DashboardBubble';
import CampaignsTable from 'components/CampaignsTable/CampaignsTable';
import SelectCampaignEventTypeModal from 'components/SelectCampaignEventTypeModal/SelectCampaignEventTypeModal';

import { useCampaigns } from 'data/campaign';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  productInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '32px 0',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },

    '& > button': {
      marginLeft: '20px',

      [theme.breakpoints.down('sm')]: {
        marginTop: '20px',
      },
    },
  },
  campaignsTable: {
    overflowX: 'auto',
    overflowY: 'auto',
  },
}));

// NOTE: AIO leads table disabled for now
// const tabs = ['Campaigns', 'CRM Leads'];
const tabs = ['Campaigns'];

const CampaignsPage = ({ eventTypeName }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { userInfo } = useUserInfoContext();
  const { availableEventTypes } = useDataFilterContext();

  const [eventType, setEventType] = React.useState(null);

  const { campaigns, mutateCampaigns } = useCampaigns(eventTypeName, ['DRAFT', 'PENDING_DOCUSIGN', 'PENDING_DATA', 'PENDING_BILLING', 'ACTIVE', 'PAUSED', 'COMPLETED', 'CANCELLED'], true);

  const [activeTab, setActiveTab] = React.useState(tabs[0]);
  const [showSelectCampaignEventTypeModal, setShowSelectCampaignEventTypeModal] = React.useState(false);

  React.useEffect(() => {
    if (!availableEventTypes || availableEventTypes.length === 0) return;

    setEventType(availableEventTypes.filter(({ name }) => name === eventTypeName)[0]);
  }, [availableEventTypes]);

  const setEventOverrides = (campaignId, eventOverrides) => {
    mutateCampaigns({
      campaigns: [
        ...campaigns.filter(({ _id }) => _id !== campaignId),
        {
          ...campaigns.filter(({ _id }) => _id === campaignId)[0],
          eventOverrides,
        },
      ],
    }, { revalidate: false });
  };

  let tabContent;

  if (activeTab === 'Campaigns') {
    tabContent = (
      <div className={classes.campaignsTable}>
        <CampaignsTable campaigns={campaigns} tableProps={{ showFilters: false }} />
      </div>
    );
  } else if (activeTab === 'CRM Leads') {
    // TODO: Multiple campaigns for AIO leads table? Might not be a good idea to add this. Could have a lot of duplicates.
    tabContent = (
      <CampaignLeadsTable campaign={campaigns[0]} setEventOverrides={setEventOverrides} />
    );
  }

  return (
    <div className={classes.root}>
      {showSelectCampaignEventTypeModal && <SelectCampaignEventTypeModal onClose={() => setShowSelectCampaignEventTypeModal(false)} />}

      <DashboardSection
        sectionName={eventTypeName}
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className={classes.productInfoContainer}>
        {eventType && (
          <DashboardBubble
            backgroundColor="#FFF8E6"
            icon={<InfoIcon htmlColor={theme.palette.secondary.main} />}
            content={(
              <>
                <strong>Campaign's Duration:</strong>
                {` ${eventType.campaignLengthInMonths} months / `}
                <strong>Touches</strong>
                {`: ${eventType.numberOfTouchesPerEvent} (Voicemail, Email, Postcard, Letter)`}
              </>
            )}
          />
        )}

        {userInfo && (
          <Button
            color="primary"
            round
            onClick={() => setShowSelectCampaignEventTypeModal(true)}
          >
            + Create New Campaign
          </Button>
        )}
      </div>
      {campaigns ? (
        <>
          {tabContent}
        </>
      ) : <LoadingIndicator />}
    </div>
  );
};

export default CampaignsPage;
