import {
  Field,
  Form, Formik,
} from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { ButtonBase } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import CustomImage, { croppingValidation } from 'creative-components/CustomUpload/CustomImage';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import BrokerageLogosModal from 'components/BrokerageLogosModal/BrokerageLogosModal';
import DashboardBubble from 'components/DashboardBubble/DashboardBubble';
import FormikOnError from 'components/FormikOnError/FormikOnError';
import SaveButton, { ESaveButtonFormikStatus } from 'components/SaveButton/SaveButton';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import { updateUser } from 'utils/api';
import { showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  brokerageLogosInfo: {
    margin: '0 auto 20px',

    '& > p': {
      '& > button': { // "Click here"
        font: 'inherit',
        verticalAlign: 'top', // Line up with text
        textDecoration: 'underline',
        color: theme.palette.orange.main,

        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  saveButtonContainer: {
    float: 'right',
    margin: '26px 0 0',
  },
}));

const ImagesForm = (props, ref) => {
  const theme = useTheme();
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { userInfo, reloadUserInfo } = useUserInfoContext();
  const { isBrokerageAgent } = useAuthDataContext();

  const [agentPictureRef] = [React.useRef(null)];

  const [isBrokerageLogosModalOpen, setIsBrokerageLogosModalOpen] = React.useState(false);

  return (
    <Formik
      innerRef={ref}
      initialValues={{
        agentPicture: userInfo.agentInfo.agentPictureUrl,
        teamLogo: userInfo.agentInfo.teamLogoUrl,
        teamLogoLight: userInfo.agentInfo.teamLogoLightUrl,
        companyLogo: userInfo.agentInfo.companyLogoUrl,
        companyLogoLight: userInfo.agentInfo.companyLogoLightUrl,
      }}
      onSubmit={async (values, { resetForm, setStatus }) => {
        const {
          agentPicture, teamLogo, teamLogoLight, companyLogo, companyLogoLight,
        } = values;

        try {
          await updateUser({
            agentInfo: {
              agentPicture,
              teamLogo,
              teamLogoLight,
              companyLogo: !isBrokerageAgent ? companyLogo : undefined,
              companyLogoLight: !isBrokerageAgent ? companyLogoLight : undefined,
            },
          });

          // Call resetForm to set the dirty var to false (used for SaveButton to detect changes again). Also
          // pass in the current values here to use that as the new initial state when resetting.
          resetForm({ values });
        } catch (err) {
          setStatus(ESaveButtonFormikStatus.Error);
          console.error('error', err);
          showAPIErrorAlert(setCurrentAlert, err);
        }
      }}
      validationSchema={Yup.object().shape({
        agentPicture: Yup.string().test(croppingValidation(agentPictureRef, setCurrentAlert)).required(),
        teamLogo: Yup.string(),
        teamLogoLight: Yup.string(),
        companyLogo: !isBrokerageAgent ? Yup.string().required() : null,
        companyLogoLight: !isBrokerageAgent ? Yup.string().optional() : null,
      })}
    >
      {(props2) => {
        const {
          touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values, validateForm,
        } = props2;

        // Validate to show errors for new users
        React.useEffect(() => { validateForm(); }, []);

        return (
          <Form>
            <FormikOnError>
              {isBrokerageLogosModalOpen && (<BrokerageLogosModal onClose={() => setIsBrokerageLogosModalOpen(false)} />)}

              <GridContainer justifyContent="center" style={{ margin: '50px 0 18px' }}>

                <GridItem xs={12}>
                  <DashboardBubble
                    rootClass={classes.brokerageLogosInfo}
                    backgroundColor={theme.palette.primary.light}
                    icon={(<CheckCircleIcon htmlColor={theme.palette.secondary.main} />)}
                    content={(
                      <>
                        These images will appear as the default in all of your marketing materials.
                        <br />
                        Image must be .jpg or .png format and smaller than 1 MB. Recommended size: 360 x 360 pixels.
                        <br />
                        <ButtonBase onClick={() => setIsBrokerageLogosModalOpen(true)}>
                          Click here
                        </ButtonBase>
                        {' for common brokerage logos.'}
                      </>
                    )}
                  />
                </GridItem>

                <GridContainer justifyContent="center">
                  <GridItem xs={12} md={5} xl={3}>
                    <Field
                      name="agentPicture"
                    >
                      {({ field }) => (
                        <CustomImage
                          ref={agentPictureRef}
                          required
                          label="Profile Image"
                          initialImage={values[field.name]}
                          onImageUpload={(imageData) => setFieldValue(field.name, imageData, true)}
                        // error={touched[field.name] && errors[field.name] !== undefined}
                          error={errors[field.name] !== undefined}
                          croppable
                        />
                      )}
                    </Field>
                  </GridItem>
                </GridContainer>

                <GridContainer justifyContent="center" alignItems="flex-end" style={{ marginTop: '40px' }}>
                  <GridItem xs={12} md={5} xl={3}>
                    <Field
                      name="companyLogo"
                    >
                      {({ field }) => (
                        <CustomImage
                          required
                          label="Brokerage Logo (Dark)"
                          initialImage={values[field.name]}
                          onImageUpload={(imageData) => setFieldValue(field.name, imageData, true)}
                          disabled={isBrokerageAgent}
                          tooltipText={isBrokerageAgent ? 'Managed by your brokerage' : ''}
                        // error={touched[field.name] && errors[field.name] !== undefined}
                          error={errors[field.name] !== undefined}
                        />
                      )}
                    </Field>
                  </GridItem>
                  <GridItem xs={12} md={5} xl={3}>
                    <Field
                      name="companyLogoLight"
                    >
                      {({ field }) => (
                        <CustomImage
                          darkBackground
                          label="Brokerage Logo (Light)"
                          initialImage={values[field.name]}
                          onImageUpload={(imageData) => setFieldValue(field.name, imageData, true)}
                          disabled={isBrokerageAgent}
                          tooltipText={isBrokerageAgent ? 'Managed by your brokerage' : ''}
                        // error={touched[field.name] && errors[field.name] !== undefined}
                          error={errors[field.name] !== undefined}
                        />
                      )}
                    </Field>
                  </GridItem>
                </GridContainer>
                <GridContainer justifyContent="center" alignItems="flex-end" style={{ marginTop: '40px' }}>
                  <GridItem xs={12} md={5} xl={3}>
                    <Field
                      name="teamLogo"
                    >
                      {({ field }) => (
                        <CustomImage
                          label="Team Logo (Dark)"
                          initialImage={values[field.name]}
                          onImageUpload={(imageData) => setFieldValue(field.name, imageData, true)}
                          onImageDelete={() => setFieldValue(field.name, '', true)}
                        // error={touched[field.name] && errors[field.name] !== undefined}
                          error={errors[field.name] !== undefined}
                        />
                      )}
                    </Field>
                  </GridItem>
                  <GridItem xs={12} md={5} xl={3}>
                    <Field
                      name="teamLogoLight"
                    >
                      {({ field }) => (
                        <CustomImage
                          darkBackground
                          label="Team Logo (Light)"
                          initialImage={values[field.name]}
                          onImageUpload={(imageData) => setFieldValue(field.name, imageData, true)}
                          onImageDelete={() => setFieldValue(field.name, '', true)}
                        // error={touched[field.name] && errors[field.name] !== undefined}
                          error={errors[field.name] !== undefined}
                        />
                      )}
                    </Field>
                  </GridItem>
                </GridContainer>

                <GridItem xs={12}>
                  <div className={classes.saveButtonContainer}>
                    <SaveButton onSave={reloadUserInfo} />
                  </div>
                </GridItem>
              </GridContainer>
            </FormikOnError>
          </Form>
        );
      }}
    </Formik>
  );
};

export default React.forwardRef(ImagesForm);
