import { useCampaignDetail } from 'data/campaign';
import {
  Field,
  Form, Formik,
} from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { ButtonBase } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LibraryAddCheckRoundedIcon from '@material-ui/icons/LibraryAddCheckRounded';

import Button from 'creative-components/CustomButtons/Button';
import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import ActionModal from 'components/ActionModal/ActionModal';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import CampaignCancelModal from 'components/CampaignSettings/CampaignCancelModal';
import ChooseAgentLandingPageType from 'components/ChooseAgentLandingPageType/ChooseAgentLandingPageType';
import ChooseTouchTemplateDesign from 'components/ChooseTouchTemplateDesign/ChooseTouchTemplateDesign';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import SaveButton, { ESaveButtonFormikStatus } from 'components/SaveButton/SaveButton';

import {
  cancelCampaign, createAutoRenewalCampaign,
  getCampaignCancellationFee, pauseCampaign, resumeCampaign, updateCampaign,
} from 'utils/api';
import { showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '60px',
    '& > div:first-child': {
      margin: '20px 0 0',
    },
    '& > div:not(:first-child)': {
      margin: '40px 0 0',
    },
    '& > div > span:first-child': {
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.palette.grayScale9.main,
    },
    '& > div > span:not(:first-child)': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.lightGray.main,
      margin: '8px 0 20px',
    },
  },
  settingContainer: {
    backgroundColor: '#F5F5F5',
    borderRadius: '12px',
    padding: '20px',
    margin: '0 0 30px',
  },
  cancelButton: {
    backgroundColor: `${theme.palette.grayScale6.main} !important`,
  },
  settingInput: {
    maxWidth: '400px',
  },
  saveButtonContainer: {
    float: 'right',
  },
}));

const CampaignSettings = ({ campaignId }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();
  const { isAdmin, isBrokerageAdmin, isAnyAgent } = useAuthDataContext();

  const { campaign, isLoadingCampaign, mutateCampaign } = useCampaignDetail(campaignId);

  const [showConfirmation, setShowConfirmation] = React.useState(null);

  const onPauseOrResumeCampaign = async () => {
    showLoadingIndicatorModal();

    try {
      if (campaign.status === 'ACTIVE') {
        await pauseCampaign(campaign._id);
        setCurrentAlert('success', `${campaign.agent.firstName} ${campaign.agent.lastName}'s ${campaign.eventType.name} campaign has been paused.`);
      } else if (campaign.status === 'PAUSED') {
        await resumeCampaign(campaign._id);
        setCurrentAlert('success', `${campaign.agent.firstName} ${campaign.agent.lastName}'s ${campaign.eventType.name} campaign has been resumed! Make sure they review the new send dates for their touches.`);
      }

      // Update the campaign
      mutateCampaign();

      setShowConfirmation(null);
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    } finally {
      setShowConfirmation(null);
      hideLoadingIndicatorModal();
    }
  };

  const onCancelCampaign = async (waiveCancellationFee) => {
    showLoadingIndicatorModal();

    try {
      await cancelCampaign(campaign._id, waiveCancellationFee);

      // Reload the page
      window.location.reload();

      setCurrentAlert('success', `${campaign.agent.firstName} ${campaign.agent.lastName}'s ${campaign.eventType.name} campaign has been cancelled.`);
    } catch (err) {
      if (err.response && err.response.data.error) {
        setCurrentAlert('error', err.response.data.error);
      } else {
        setCurrentAlert('error', 'Something went wrong, please try again.');
      }

      console.error('error', err);
    } finally {
      setShowConfirmation(null);
      hideLoadingIndicatorModal();
    }
  };

  const onCreateAutoRenewalCampaign = async () => {
    showLoadingIndicatorModal();

    try {
      const { autoRenewalCampaign, autoRenewalCampaignAlreadyExisted, autoRenewalCampaignDeleted } = await createAutoRenewalCampaign(campaign._id);

      const campaignLink = (
        <Link to={`/dashboard/campaigns/${autoRenewalCampaign._id}`} target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.white.main, textDecoration: 'underline' }}>
          Go to Auto-Renewal Campaign
        </Link>
      );

      if (autoRenewalCampaignAlreadyExisted) {
        if (autoRenewalCampaignDeleted) {
          setCurrentAlert('warning', (<>This campaign was already set to auto-renew, but the renewal was cancelled.</>));
        } else {
          setCurrentAlert('warning', (
            <>
              {'This campaign is already scheduled to auto-renew. You may check the details here: '}
              {campaignLink}
            </>
          ));
        }
      } else {
        setCurrentAlert('success', (
          <>
            {'An auto-renewal campaign has been created. You may check the details here: '}
            {campaignLink}
          </>
        ));
      }
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    } finally {
      setShowConfirmation(null);
      hideLoadingIndicatorModal();
    }
  };

  const onClickPauseOrResumeCampaign = () => {
    setShowConfirmation(<ActionModal
      backgroundColor={theme.palette.offWhite.main}
      textColor={theme.palette.darkGray.main}
      icon={<LibraryAddCheckRoundedIcon />}
      onClose={() => setShowConfirmation(null)}
      title="Are You Sure?"
      message={`Are you sure you want to ${campaign.status === 'ACTIVE' ? 'pause' : 'resume'} this campaign?`}
      buttons={[
        <ButtonBase onClick={() => setShowConfirmation(null)} className={classes.cancelButton}>No</ButtonBase>,
        <ButtonBase onClick={onPauseOrResumeCampaign}>Yes</ButtonBase>,
      ]}
    />);
  };

  const onClickCancelCampaign = async () => {
    showLoadingIndicatorModal();

    try {
      // Fetch how much it will cost the agent to cancel this campaign
      const data = await getCampaignCancellationFee(campaign._id);

      setShowConfirmation(<CampaignCancelModal
        campaign={campaign}
        cancellationFee={data.cancellationFee}
        onConfirm={onCancelCampaign}
        onClose={() => setShowConfirmation(null)}
      />);
    } catch (error) {
      if (error.response && error.response.data.error) {
        setCurrentAlert('error', error.response.data.error);
      } else {
        setCurrentAlert('error', 'Something went wrong, please try again.');
      }

      console.error('error', error);
    }

    hideLoadingIndicatorModal();
  };

  const onClickCreateAutoRenewalCampaign = async () => {
    setShowConfirmation(<ActionModal
      backgroundColor={theme.palette.offWhite.main}
      textColor={theme.palette.darkGray.main}
      icon={<LibraryAddCheckRoundedIcon />}
      onClose={() => setShowConfirmation(null)}
      title="Auto-Renew This Campaign?"
      message="Are you sure you want to renew this campaign which, unless cancelled by the user, will automatically start in 30 days?"
      buttons={[
        <ButtonBase onClick={() => setShowConfirmation(null)} className={classes.cancelButton}>No</ButtonBase>,
        <ButtonBase onClick={onCreateAutoRenewalCampaign}>Yes</ButtonBase>,
      ]}
    />);
  };

  if (isLoadingCampaign) {
    return (
      <div className={classes.root}>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {showConfirmation}

      {isAnyAgent && campaign.status !== 'COMPLETED' && (
        <Formik
          initialValues={{
            farmAreaName: campaign.farmAreaName,
            agentLandingPageType: campaign.agentLandingPageType,
            touchTemplateDesignId: campaign.touchTemplateDesign,
          }}
          onSubmit={async (values, { resetForm, setStatus }) => {
            const { farmAreaName, agentLandingPageType, touchTemplateDesignId } = values;

            try {
              await updateCampaign(campaignId, { farmAreaName, agentLandingPageType, touchTemplateDesignId });

              mutateCampaign({
                campaign: {
                  ...campaign,
                  farmAreaName,
                  agentLandingPageType,
                },
              }, { revalidate: true });

              setCurrentAlert('success', 'Your campaign has been updated');

              // Call resetForm to set the dirty var to false (used for SaveButton to detect changes again). Also
              // pass in the current values here to use that as the new initial state when resetting.
              resetForm({ values });
            } catch (err) {
              setStatus(ESaveButtonFormikStatus.Error);
              console.error('error', err);
              showAPIErrorAlert(setCurrentAlert, err);
            }
          }}
          validationSchema={Yup.object().shape({
            farmAreaName: Yup.string().required(),
            agentLandingPageType: Yup.string().required(),
            touchTemplateDesignId: Yup.string().required(),
          })}
        >
          {({
            errors,
          }) => (
            <Form>
              <GridContainer justifyContent="flex-start">
                <GridItem xs={12} lg={6}>
                  <div className={classes.settingContainer}>
                    <Field name="farmAreaName">
                      {({ field }) => (
                        <CustomInputDashboard
                          labelText="Farm Area Name"
                          inputProps={{
                            ...field,
                            placeholder: 'Enter name',
                            endAdornment: <InfoOutlinedIcon />,
                            className: classes.settingInput,
                          }}
                          error={errors[field.name] !== undefined}
                          tooltip="This will be your farm area name merged onto certain marketing materials and refers to you being an expert in that community, neighborhood, zipcode and/or city, i.e. Hollywood Hills, The Westside, Crescent Heights"
                        />
                      )}
                    </Field>
                  </div>
                </GridItem>

                <GridItem xs={12} lg={6}>
                  {/* Only show the agent landing page selector for non-commercial properties since we don't do comparables for commercial properties */}
                  {campaign.eventType.name !== 'Commercial' && (
                    <div className={classes.settingContainer}>
                      <ChooseAgentLandingPageType />
                    </div>
                  )}
                </GridItem>

                <GridItem xs={12}>
                  <div className={classes.settingContainer}>
                    <ChooseTouchTemplateDesign eventType={campaign.eventType} campaignId={campaign._id} />
                  </div>
                </GridItem>

                <GridItem xs={12}>
                  <div className={classes.saveButtonContainer}>
                    <SaveButton />
                  </div>
                </GridItem>
              </GridContainer>
            </Form>
          )}
        </Formik>
      )}

      {(isAdmin || isBrokerageAdmin) && ['ACTIVE', 'PAUSED'].includes(campaign.status) && (
        <div>
          <span>
            {campaign.status === 'ACTIVE' ? 'Pause' : 'Resume'}
            {' '}
            Campaign
          </span>
          <br />
          <br />
          <span>
            Pause and resume this campaign at any time. Billing is paused and touches don't go out for paused
            campaigns. When resuming this campaign, the touch dates for the remaining unsent touches will be updated.
          </span>
          <br />
          <br />
          <div style={{
            display: 'flex', direction: 'row', gap: '20px', flexWrap: 'wrap',
          }}
          >
            <div>
              <Button
                color="primary"
                round
                onClick={onClickPauseOrResumeCampaign}
              >
                {campaign.status === 'ACTIVE' ? 'Pause' : 'Resume'}
                {' '}
                Campaign
              </Button>
            </div>
            <div>
              {['ACTIVE', 'PAUSED'].includes(campaign.status) && (
                <Button
                  onClick={onClickCancelCampaign}
                  color="orange"
                  round
                >
                  Cancel Campaign
                </Button>
              )}
            </div>
          </div>
        </div>
      )}

      {(isAdmin || isBrokerageAdmin) && campaign.status === 'COMPLETED' && (
        <div>
          <span>
            Auto-Renew Campaign
          </span>
          <br />
          <br />
          <span>
            Creates a new draft campaign that will automatically start once this current campaign ends.
            The farm area will be unchanged and the same APNs will be in the new campaign.
            The user may change campaign settings like edit touches, add extra recipients, and change marketing designs
            before the campaign starts. The draft campaign may also be discarded at any time before it starts.
          </span>
          <br />
          <br />
          <div style={{
            display: 'flex', direction: 'row', gap: '20px', flexWrap: 'wrap',
          }}
          >
            <div>
              <Button
                color="primary"
                round
                onClick={onClickCreateAutoRenewalCampaign}
              >
                Auto-Renew Campaign
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignSettings;
