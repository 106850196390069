import { useAllCampaigns } from 'data/campaign';
import moment from 'moment';
import React from 'react';

import { ButtonBase, Tooltip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import InfoIcon from '@material-ui/icons/Info';
import SendRoundedIcon from '@material-ui/icons/SendRounded';

import Button from 'creative-components/CustomButtons/Button';
import ReactTable from 'creative-components/ReactTable/ReactTable';

import ActionModal from 'components/ActionModal/ActionModal';
import AgentInfoModal from 'components/AgentsTable/AgentInfoModal';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import AssignAgentBranchesModal from 'components/AssignAgentBranchesModal/AssignAgentBranchesModal';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';

import { authenticateAssumeUser, deleteUser, resendSignUpEmail } from 'utils/api';
import { enumToReadableName, showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    backgroundColor: `${theme.palette.grayScale6.main} !important`,
  },
}));

export default function BrokerageAgentsTable({ brokerage, mutateBrokerage, reloadQuota }) {
  const classes = useStyles();
  const theme = useTheme();
  const { onLogin, isBrokerageAdmin } = useAuthDataContext();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const [isConfirmDelete, setIsConfirmDelete] = React.useState(null);

  const { campaigns, isLoadingCampaigns } = useAllCampaigns(undefined, brokerage._id, !isBrokerageAdmin);

  const onDeleteAccount = async () => {
    const user = isConfirmDelete;

    showLoadingIndicatorModal();

    try {
      await deleteUser(user._id);

      mutateBrokerage({
        brokerage: {
          ...brokerage,
          agents: brokerage.agents.filter(({ _id }) => _id !== user._id),
        },
      }, { revalidate: false });

      if (reloadQuota) reloadQuota(); // For brokerage admins

      setCurrentAlert('success', `${isConfirmDelete.firstName}'s account has been deleted.`);

      setIsConfirmDelete(null);
    } catch (error) {
      if (error.response && error.response.data.error) {
        setCurrentAlert('error', error.response.data.error);
      } else {
        setCurrentAlert('error', 'Something went wrong, please try again.');
      }

      console.error('error', error);
    }

    hideLoadingIndicatorModal();
  };

  const onAssumeUser = async (userId) => {
    showLoadingIndicatorModal();
    try {
      const { token, expiresInDays } = await authenticateAssumeUser(userId);

      // Call the login function on the auth context
      onLogin(token, expiresInDays);
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  const onResendSignUpEmail = async (userId, firstName, lastName) => {
    showLoadingIndicatorModal();
    try {
      await resendSignUpEmail(userId);

      setCurrentAlert('success', `Re-sent a sign up email to ${firstName} ${lastName}`);
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  const tableRef = React.createRef();

  const [openModal, setOpenModal] = React.useState(null);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      if (!brokerage || (!isBrokerageAdmin && isLoadingCampaigns)) return;

      const users = brokerage.agents;

      // Sort by createdAt desc
      users.sort((a, b) => (moment(a.createdAt).isAfter(moment(b.createdAt)) ? 1 : -1));

      const agentIdsToBranchNameMapping = brokerage.branches.reduce((b, v) => {
        const newO = { ...b };

        v.agentIds.forEach((_id) => {
          newO[_id] = v.name;
        });

        return newO;
      }, {});

      setData(users.map((user) => ({
        _id: user._id,
        name: `${user.firstName} ${user.lastName ?? ''}`,
        email: user.email,
        status: enumToReadableName(user.status),
        branch: agentIdsToBranchNameMapping[user._id],
        createdAt: moment(user.createdAt).toDate(),
        actions: (
          <div>
            {user.status === 'UNREGISTERED' && (
              <Tooltip
                title="Resend Invite Email"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => onResendSignUpEmail(user._id, user.firstName, user.lastName)}
                  color="primary"
                >
                  <SendRoundedIcon />
                </Button>
              </Tooltip>
            )}

            {user.status === 'ACTIVE' && (
              <Button
                onClick={() => onAssumeUser(user._id)}
                color="orange"
                round
                size="sm"
              >
                Impersonate
              </Button>
            )}

            {!isBrokerageAdmin && (
              <Tooltip
                title="Info"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    setOpenModal((
                      <AgentInfoModal
                        user={user}
                        agentCampaigns={campaigns.filter((c) => c.agent._id === user._id)}
                        setOpenModal={setOpenModal}
                      />
                    ));
                  }}
                  color="dark"
                >
                  <InfoIcon />
                </Button>
              </Tooltip>
            )}

            {user.status === 'UNREGISTERED' && (
              <Tooltip
                title="Delete Account"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => setIsConfirmDelete(user)}
                  color="orange"
                >
                  <DeleteRoundedIcon />
                </Button>
              </Tooltip>
            )}
          </div>
        ),
      })));
    })();
  }, [brokerage, isLoadingCampaigns]);

  React.useEffect(() => {
    if (!tableRef.current || !isBrokerageAdmin) return;

    if (!openModal) {
      tableRef.current.deselectAllRows();
    }
  }, [openModal]);

  if (!brokerage || (!isBrokerageAdmin && isLoadingCampaigns)) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {openModal}

      {isConfirmDelete && (
        <ActionModal
          backgroundColor={theme.palette.offWhite.main}
          textColor={theme.palette.darkGray.main}
          icon={<DeleteRoundedIcon />}
          onClose={() => setIsConfirmDelete(null)}
          title="Are You Sure?"
          message={`Are you sure you want to delete ${isConfirmDelete.firstName}'s account?`}
          buttons={[
            <ButtonBase onClick={() => setIsConfirmDelete(null)} className={classes.cancelButton}>No</ButtonBase>,
            <ButtonBase onClick={onDeleteAccount}>Yes</ButtonBase>,
          ]}
        />
      )}

      <ReactTable
        columns={[
          {
            Header: 'Branch',
            accessor: 'branch',
          },
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Email',
            accessor: 'email',
          },
          {
            Header: 'Status',
            accessor: 'status',
          },
          {
            Header: 'Date Created',
            accessor: 'createdAt',
            Cell: ({ cell: { value } }) => moment(value).format('MMM D, YYYY'),
            sortType: 'datetime',
          },
          {
            Header: 'Actions',
            accessor: 'actions',
          },
        ]}
        ref={tableRef}
        data={data}
        normalRightColumn={false}
        selectableRows={isBrokerageAdmin}
        renderRightColumnComponentOnlyWhenRowsSelected
        // TODO: RESPONSIVENESS on the right column. When selecting too.
        rightColumnComponent={isBrokerageAdmin && (
          <Tooltip
            title="Reassign agent(s) brokerage branch"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              round
              color="orange"
              size="sm"
              onClick={() => {
                if (!tableRef.current || tableRef.current.getSelectedFlatRows().length === 0) return;

                setOpenModal((
                  <AssignAgentBranchesModal
                    agentIds={tableRef.current.getSelectedFlatRows().map(({ original }) => original._id)}
                    onClose={() => setOpenModal(null)}
                  />
                ));
              }}
            >
              Assign Branch
            </Button>
          </Tooltip>
        )}
      />
    </>
  );
}
