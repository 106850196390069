import {
  Field,
  Form, Formik,
} from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';
import CustomImage, { croppingValidation } from 'creative-components/CustomUpload/CustomImage';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import DashboardBubble from 'components/DashboardBubble/DashboardBubble';
import FormikOnError from 'components/FormikOnError/FormikOnError';
import SaveButton, { ESaveButtonFormikStatus } from 'components/SaveButton/SaveButton';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import { updateUser } from 'utils/api';
import {
  nameRegExp, phoneRegExp, showAPIErrorAlert, urlRegExp,
} from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  infoBubble: {
    margin: '0 auto 47px',
  },
  topMargin: {
    margin: '47px 0 0',
  },
  saveButtonContainer: {
    float: 'right',
    margin: '26px 0 0',
  },
}));

const SecondProfileDataForm = (props, ref) => {
  const classes = useStyles();
  const theme = useTheme();
  const { setCurrentAlert } = useAlertContext();
  const { userInfo, reloadUserInfo } = useUserInfoContext();

  const [pictureRef] = [React.useRef(null)];

  return (
    <>
      <Formik
        innerRef={ref}
        initialValues={{
          secondProfileFirstName: userInfo.agentInfo.secondProfileFirstName || '',
          secondProfileLastName: userInfo.agentInfo.secondProfileLastName || '',
          secondProfileEmailAddress: userInfo.agentInfo.secondProfileEmailAddress || '',
          secondProfilePhoneNumber: userInfo.agentInfo.secondProfilePhoneNumber || '',
          secondProfileWebsite: userInfo.agentInfo.secondProfileWebsite || '',
          secondProfileLicenseNumber: userInfo.agentInfo.secondProfileLicenseNumber || '',
          secondProfilePicture: userInfo.agentInfo.secondProfilePictureUrl,
        }}
        onSubmit={async (values, { resetForm, setStatus }) => {
          const {
            secondProfileFirstName,
            secondProfileLastName,
            secondProfileEmailAddress,
            secondProfilePhoneNumber,
            secondProfileWebsite,
            secondProfileLicenseNumber,
            secondProfilePicture,
          } = values;

          try {
            await updateUser({
              agentInfo: {
                secondProfileFirstName,
                secondProfileLastName,
                secondProfileEmailAddress,
                secondProfilePhoneNumber,
                secondProfileWebsite,
                secondProfileLicenseNumber,
                secondProfilePicture,
              },
            });

            // Call resetForm to set the dirty var to false (used for SaveButton to detect changes again). Also
            // pass in the current values here to use that as the new initial state when resetting.
            resetForm({ values });
          } catch (err) {
            setStatus(ESaveButtonFormikStatus.Error);
            console.error('error', err);
            showAPIErrorAlert(setCurrentAlert, err);
          }
        }}
        validationSchema={Yup.object().shape({
          secondProfileFirstName: Yup.string().required().matches(nameRegExp),
          secondProfileLastName: Yup.string().required().matches(nameRegExp),
          secondProfileEmailAddress: Yup.string().email().required(),
          secondProfilePhoneNumber: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
          secondProfileWebsite: Yup.string().url().required().matches(urlRegExp),
          secondProfileLicenseNumber: Yup.string().required(),
          secondProfilePicture: Yup.string().test(croppingValidation(pictureRef, setCurrentAlert)).required(),
        })}
      >
        {(props2) => {
          const { setFieldValue, values } = props2;

          return (
            <Form>
              <FormikOnError>
                <GridContainer justifyContent="center">
                  <GridItem xs={12} sm={10} lg={9} xl={7}>
                    <GridContainer justifyContent="center" className={classes.topMargin}>

                      <GridItem xs={12}>
                        <DashboardBubble
                          rootClass={classes.infoBubble}
                          backgroundColor={theme.palette.primary.light}
                          icon={(<CheckCircleIcon htmlColor={theme.palette.secondary.main} />)}
                          content="Some marketing material design templates are made to display a second profile in addition to your main Harvist profile. These templates are useful for agents who want to have two different people on their marketing materials."
                        />
                      </GridItem>

                      <GridContainer justifyContent="center" style={{ marginBottom: '30px' }}>
                        <GridItem xs={12} md={5} xl={3}>
                          <Field
                            name="secondProfilePicture"
                          >
                            {({ field, meta: { error } }) => (
                              <CustomImage
                                ref={pictureRef}
                                label="Second Profile Image"
                                initialImage={values[field.name]}
                                onImageUpload={(imageData) => setFieldValue(field.name, imageData, true)}
                                error={error !== undefined}
                                croppable
                              />
                            )}
                          </Field>
                        </GridItem>
                      </GridContainer>

                      <GridItem xs={12} md={6}>
                        <Field
                          name="secondProfileFirstName"
                        >
                          {({ field, meta: { error } }) => (
                            <CustomInputDashboard
                              labelText="Second Profile First Name"
                              inputProps={{
                                ...field,
                                autoComplete: 'given-name',
                                placeholder: 'Enter name',
                                maxLength: 50,
                              }}
                              error={error !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12} md={6}>
                        <Field
                          name="secondProfileLastName"
                        >
                          {({ field, meta: { error } }) => (
                            <CustomInputDashboard
                              labelText="Second Profile Last Name"
                              inputProps={{
                                ...field,
                                autoComplete: 'family-name',
                                placeholder: 'Enter name',
                                maxLength: 50,
                              }}
                              error={error !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem>

                      <GridItem xs={12} md={6}>
                        <Field
                          name="secondProfileEmailAddress"
                        >
                          {({ field, meta: { error } }) => (
                            <CustomInputDashboard
                              labelText="Second Profile Email Address"
                              inputProps={{
                                ...field,
                                autoComplete: 'email',
                              }}
                              error={error !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12} md={6}>
                        <Field
                          name="secondProfilePhoneNumber"
                        >
                          {({ field, meta: { error } }) => (
                            <CustomInputDashboard
                              labelText="Second Profile Phone Number"
                              inputProps={{
                                ...field,
                                type: 'tel',
                                autoComplete: 'tel',
                                endAdornment: <InfoOutlinedIcon />,
                              }}
                              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                              error={error !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem>

                      <GridItem xs={12} md={6}>
                        <Field
                          name="secondProfileWebsite"
                        >
                          {({ field, meta: { error } }) => (
                            <CustomInputDashboard
                              labelText="Second Profile Website"
                              inputProps={{
                                ...field,
                              }}
                              mask={(rawValue) => (['h', 't', 't', 'p', 's', ':', '/', '/', ...rawValue.split('').map(() => /./)])}
                              error={error !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem>

                      <GridItem xs={12} md={6}>
                        <Field
                          name="secondProfileLicenseNumber"
                        >
                          {({ field, meta: { error } }) => (
                            <CustomInputDashboard
                              labelText="Second Profile License Number"
                              inputProps={{
                                ...field,
                                placeholder: '#',
                              }}
                              error={error !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem>

                      <GridItem xs={12}>
                        <div className={classes.saveButtonContainer}>
                          <SaveButton onSave={reloadUserInfo} />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </FormikOnError>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default React.forwardRef(SecondProfileDataForm);
