import customCheckboxRadioSwitchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';
import cx from 'classnames';
import { useTouchTemplateDesigns } from 'data/campaign';
import { Field, useFormikContext } from 'formik';
import React from 'react';

import { FormControlLabel, Radio } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  ...customCheckboxRadioSwitchStyles(theme),
  root: {
    '& a': {
      color: theme.palette.orange.main,
      textDecoration: 'underline',
    },
    '& label': {
      marginRight: '40px',
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.darkGray.main,
  },
  subTitle: {
    fontSize: '14px',
    color: theme.palette.gray.main,
    fontWeight: 500,
    margin: '6px 0 10px',
  },
  designContainer: {
    marginTop: '20px',
    textAlign: 'center',
  },
  designOption: {
    padding: '20px',
  },
  designPreviews: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > div:not(:last-child)': {
      marginRight: '12px',
    },
  },

  previewContainer: {
    marginTop: '10px',
    position: 'relative',
    width: '256px',
    height: '100%',
    border: `1px solid ${theme.palette.lightGray.main}`,

    '& > img': {
      minHeight: '200px',
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
      margin: 'auto',
    },

    '&:hover': {
      backgroundColor: red,
      '& > div': {
        visibility: 'visible',
      },
    },
  },
  previewContainerSelected: {
    border: `3px solid ${theme.palette.orange.main}`,
  },
  previewHover: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    visibility: 'hidden',
  },
  previewButton: {
    borderRadius: '8px',
    padding: '10px 20px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.white.main,
    backgroundColor: theme.palette.primary.main,

    '& > a': {
      textDecoration: 'none',
      color: theme.palette.white.main,
    },
  },
}));

const ImagePreview = ({ selected, imageLink, alt }) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.previewContainer, selected && classes.previewContainerSelected)}>
      <img src={imageLink} alt={alt} />
      <div className={classes.previewHover}>
        <ButtonBase className={classes.previewButton}>
          <a href={imageLink} target="_blank" rel="noreferrer">Preview</a>
        </ButtonBase>
      </div>
    </div>
  );
};

const ChooseTouchTemplateDesign = ({ eventType, campaignId = null }) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();

  const { touchTemplateDesigns, isLoadingTouchTemplateDesigns } = useTouchTemplateDesigns(campaignId);

  // TODO: WHAT IF YOU HAVE AN INACTIVE ONE SELECTED? SHOW A MESSAGE TO LET THEM KNOW THEIR CURRENT ONE IS NO LONGER OFFERED BY THEM AND IF THEY CHANGE IT THEY WONT BE ABLE TO GO BACK TO IT.

  React.useEffect(() => {
    // Select first one by default if this is a new campaign
    if (values.touchTemplateDesignId || isLoadingTouchTemplateDesigns || !touchTemplateDesigns.length) return;

    setFieldValue('touchTemplateDesignId', touchTemplateDesigns[0]._id, false);
  }, [isLoadingTouchTemplateDesigns]);

  // Check if touch template designs are still loading. Use this check instead of isLoadingTouchTemplateDesigns.
  if (values.touchTemplateDesignId === null) return (<LoadingIndicator />);

  return (
    <Field name="touchTemplateDesignId">
      {({ field }) => (
        <div className={classes.root}>
          <h4 className={classes.title}>
            Marketing Material Designs
          </h4>
          <h5 className={classes.subTitle}>
            Select your desired designs for the marketing material for this campaign.
            You may change your designs at any point during your campaign.
          </h5>
          {!isLoadingTouchTemplateDesigns ? (
            <GridContainer className={classes.designContainer} spacing={2} alignItems="center" justifyContent="flex-start">
              {touchTemplateDesigns.filter(({ designs }) => designs.find((o) => o.eventType === eventType._id))
                .map(({
                  _id, name, sampleLetterUrl, samplePostcardUrl,
                }) => (
                  <GridItem xs={12} md={6} lg={4} key={_id}>
                    <div className={classes.designOption}>
                      <FormControlLabel
                        control={(
                          <Radio
                            {...field}
                            checked={field.value === _id}
                            onChange={() => setFieldValue(field.name, _id, true)}
                            icon={(
                              <FiberManualRecordIcon
                                className={classes.radioUnchecked}
                              />
                          )}
                            checkedIcon={(
                              <FiberManualRecordIcon
                                className={classes.radioChecked}
                              />
                          )}
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                      )}
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label={name}
                      />
                      <div className={classes.designPreviews}>
                        <ImagePreview selected={field.value === _id} imageLink={sampleLetterUrl} alt="Sample Letter" />
                        <ImagePreview selected={field.value === _id} imageLink={samplePostcardUrl} alt="Sample Postcard" />
                      </div>
                    </div>
                  </GridItem>
                ))}
            </GridContainer>
          ) : <LoadingIndicator />}
        </div>
      )}
    </Field>
  );
};

export default ChooseTouchTemplateDesign;
