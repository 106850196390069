import { useCampaignDetail, useTouchTemplateDesigns } from 'data/campaign';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import PeopleIcon from '@material-ui/icons/People';

import Button from 'creative-components/CustomButtons/Button';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import CampaignEngagementsTable from 'components/CampaignEngagementsTable/CampaignEngagementsTable';
import CampaignExtraRecipientsTable from 'components/CampaignExtraRecipientsTable/CampaignExtraRecipientsTable';
import CampaignInfoCards from 'components/CampaignInfoCards/CampaignInfoCards';
import CampaignLeadsTable from 'components/CampaignLeadsTable/CampaignLeadsTable';
import CampaignSettings from 'components/CampaignSettings/CampaignSettings';
import CampaignSteps from 'components/CampaignSteps/CampaignSteps';
import MarketingProfileIncompleteModal from 'components/CampaignSteps/MarketingProfileIncompleteModal';
import MissingVoicemailsModal from 'components/CampaignSteps/MissingVoicemailsModal';
import DashboardBubble from 'components/DashboardBubble/DashboardBubble';
import DashboardSection from 'components/DashboardSection/DashboardSection';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import CampaignStats from 'components/Stats/CampaignStats';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import { updateCampaign } from 'utils/api';
import {
  checkNextTouchIsVMWithUnassignedRecording, isCampaignActiveOrCompleted, isCampaignCancelled, isCampaignDraft, isCampaignPaused, isCampaignPending,
  isMarketingProfileCompleted, isMarketingSecondProfileCompleted, showAPIErrorAlert, sortTouchesForCampaignSteps,
} from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  instructionsBubble: {
    margin: '47px auto 32px',

    '& > button': { // "Click here"
      font: 'inherit',
      verticalAlign: 'top', // Line up with text
      textDecoration: 'underline',
      color: theme.palette.orange.main,

      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },

  adminInfo: {
    padding: '32px 16px',
    fontWeight: 400,
    fontSize: '14px',
  },

  steps: {
    margin: '50px 0 0',
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    margin: '50px 0 0',
  },

  pendingCampaignLeadsInfo: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '36px',
    color: theme.palette.black.main,
  },
}));

const initialTab = 'Touches';

const CampaignDetailPage = () => {
  const { campaignId } = useParams();

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const {
    DASHBOARD_ROUTE, isAdmin, isBrokerageAdmin, isAnyAgent,
  } = useAuthDataContext();
  const { userInfo } = useUserInfoContext();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  if (!campaignId) {
    // Invalid url params. Redirect to dashboard
    history.replace(DASHBOARD_ROUTE);
  }

  let urlTab;
  if (location.state && location.state.tab) {
    urlTab = location.state.tab;
  }

  const {
    campaign, isLoadingCampaign, isLoadingCampaignError, mutateCampaign,
  } = useCampaignDetail(campaignId);

  const { touchTemplateDesigns, isLoadingTouchTemplateDesigns } = useTouchTemplateDesigns(campaignId);

  const [activeTab, setActiveTab] = React.useState(urlTab || initialTab);

  const [nextTouchIsVMWithUnassignedRecording, setNextTouchIsVMWithUnassignedRecording] = React.useState(false);
  const [showMarketingProfileIncompleteModal, setShowMarketingProfileIncompleteModal] = React.useState(false);

  React.useEffect(() => {
    if (isLoadingCampaignError) {
      // Likely caused by an invalid campaign id in the url params. Redirect to dashboard
      history.push(DASHBOARD_ROUTE);
      console.error(isLoadingCampaignError);
      return;
    }

    if (isLoadingCampaign || isLoadingTouchTemplateDesigns) return;

    if (campaign.status === 'DRAFT') {
      // Campaign is still in the draft state, so redirect to the create campaign page
      history.replace(`/dashboard/create-campaign?campaign=${campaign._id}`);
      return;
    }

    // Check if there are any VM touches with unassigned VM recordings (only for active campaigns)
    if (campaign.status === 'ACTIVE' && checkNextTouchIsVMWithUnassignedRecording(campaign.touchTriggers)) {
      // NOTE: Disabled this pop-up since we added fallback emails to VM touches
      setNextTouchIsVMWithUnassignedRecording(true);
    }

    // Check if user hasn't completed their marketing profile
    if (isAnyAgent && campaign.status === 'ACTIVE') {
      const activeTouchTemplateDesign = touchTemplateDesigns.find(({ _id }) => _id === campaign.touchTemplateDesign);

      // If touch design uses the second profile, make sure thats completed too
      if (!isMarketingProfileCompleted(userInfo?.agentInfo) || (activeTouchTemplateDesign.usesSecondProfile && !isMarketingSecondProfileCompleted(userInfo?.agentInfo))) {
        setShowMarketingProfileIncompleteModal(true);
      }
    }
  }, [isLoadingCampaign, isLoadingTouchTemplateDesigns, isLoadingCampaignError, userInfo]);

  const onUpdateCampaignName = async (name) => {
    showLoadingIndicatorModal();
    try {
      await updateCampaign(campaignId, { name });

      mutateCampaign({
        campaign: {
          ...campaign,
          name,
        },
      }, { revalidate: false });

      setCurrentAlert('success', 'Campaign name updated');
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  const setEventOverrides = (eventOverrides) => {
    mutateCampaign({
      campaign: {
        ...campaign,
        eventOverrides,
      },
    }, { revalidate: false });
  };

  // NOTE: DocuSign disabled
  /* const onResendDocusign = async () => {
    showLoadingIndicatorModal();
    try {
      await resendDocuSignEmail(campaign._id);

      setCurrentAlert('success', 'We have re-sent the DocuSign link to your email!');
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  }; */

  let tabContent;

  if (activeTab === 'Touches') {
    const shouldShowCampaignSteps = campaign && (isCampaignPending(campaign.status) || isCampaignActiveOrCompleted(campaign.status) || isCampaignPaused(campaign.status));

    tabContent = (
      <div className={classes.steps}>
        {shouldShowCampaignSteps && (
          <CampaignSteps
            agent={userInfo}
            eventType={campaign.eventType}
            initialTouchTriggers={sortTouchesForCampaignSteps(campaign)}
            campaignStartDate={!isCampaignPaused(campaign.status) ? campaign.campaignStartDate : null} // Don't show dates for paused campaigns
            campaignCreatedDate={campaign.createdAt}
          />
        )}
      </div>
    );
  } else if (activeTab === 'Stats') {
    tabContent = (<CampaignStats campaign={campaign} setActiveTab={setActiveTab} />);
  } else if (activeTab === 'Prospects') {
    if (campaign) {
      if (isCampaignActiveOrCompleted(campaign.status) || isCampaignPaused(campaign.status)) {
        tabContent = (
          <div className={classes.table}>
            <CampaignLeadsTable campaign={campaign} setEventOverrides={setEventOverrides} />
          </div>
        );
      } else if (isCampaignPending(campaign.status)) {
        tabContent = (
          <div className={classes.pendingCampaignLeadsInfo}>
            Your farming leads will show up here when your campaign starts! 👀
          </div>
        );
      }
    } else {
      tabContent = (<LoadingIndicator />);
    }
  } else if (activeTab === 'Extra Recipients') {
    tabContent = (<CampaignExtraRecipientsTable campaign={campaign} />);
  } else if (activeTab === 'Settings') {
    tabContent = (<CampaignSettings campaignId={campaignId} />);
  } else if (activeTab === 'Stats - Hot Prospects') { // Hidden tab
    tabContent = (
      <div className={classes.table}>
        <CampaignEngagementsTable campaign={campaign} hotProspectsOnly />
      </div>
    );
  } else if (activeTab === 'Stats - Engagements') { // Hidden tab
    tabContent = (
      <div className={classes.table}>
        <CampaignEngagementsTable campaign={campaign} hotProspectsOnly={false} />
      </div>
    );
  }

  if (isLoadingCampaign) {
    return (
      <div className={classes.root}>
        <LoadingIndicator />
      </div>
    );
  }

  const leadsButton = (
    <Button
      color="primary"
      round
      onClick={() => setActiveTab('Prospects')}
    >
      <PeopleIcon />
      Prospects
    </Button>
  );

  // TODO: REFACTOR THESE INTO DIFFERENT URL PATHS AND FIX BREADCRUMBS????
  let tabsToShow = ['Touches', 'Stats', 'Extra Recipients', 'Settings'];

  // Only show the stats tab for specific campaign statuses
  if (!['ACTIVE', 'COMPLETED', 'PAUSED', 'CANCELLED'].includes(campaign.status)) {
    tabsToShow = tabsToShow.filter((e) => e !== 'Stats');
  }

  // Don't show the settings tab for cancelled campaigns
  if (campaign.status === 'CANCELLED') {
    tabsToShow = tabsToShow.filter((e) => e !== 'Settings');
  }

  let subsectionName = null;
  if (activeTab === 'Stats - Hot Prospects') {
    subsectionName = 'Hot Prospect Events';
  } else if (activeTab === 'Stats - Engagements') {
    subsectionName = 'Engagement Events';
  }

  return (
    <div className={classes.root}>
      {nextTouchIsVMWithUnassignedRecording && <MissingVoicemailsModal onClose={() => setNextTouchIsVMWithUnassignedRecording(false)} />}
      {showMarketingProfileIncompleteModal && <MarketingProfileIncompleteModal onClose={() => setShowMarketingProfileIncompleteModal(false)} />}

      <DashboardSection
        // breadcrumbs={[{ name: campaign.eventType.name, to: location.state ? location.state.previousPathname : '/dashboard' }]}
        // breadcrumbs={[{ name: isAdmin || isAccountManager ? 'All Campaigns' : 'My Campaigns', to: location.state ? location.state.previousPathname : '/dashboard' }]}
        breadcrumbs={[{ name: 'Campaigns', to: location.state ? location.state.previousPathname : '/dashboard' }]}
        sectionName={campaign.name}
        sectionNameEditable={isAnyAgent}
        onSectionEditName={onUpdateCampaignName}
        subsectionName={subsectionName}
        tabs={tabsToShow}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        buttons={!isCampaignCancelled(campaign.status) ? [leadsButton] : []}
        buttonLayout="right"
      />

      {(isAdmin || isBrokerageAdmin) && (
        <div className={classes.adminInfo}>
          <b>Type: </b>
          {campaign.eventType.name}
          <br />
          <b>Agent: </b>
          {`${campaign.agent.firstName} ${campaign.agent.lastName} (${campaign.agent._id})`}
        </div>
      )}

      {/* Don't show these info cards for the stats tab */}
      {activeTab !== 'Stats' && (
        <CampaignInfoCards
          startDate={campaign.campaignStartDate}
          endDate={campaign.campaignEndDate}
          apnsTotalSelectedCount={isCampaignDraft(campaign.status) || isCampaignPending(campaign.status) ? campaign.estimatedNumberOfApns : campaign.subscribedEvents.length}
          apnsFarmingStoppedCount={campaign.eventOverrides.filter((o) => o.farmingStopped).length}
          campaignCosts={isCampaignDraft(campaign.status) || isCampaignPending(campaign.status) ? campaign.estimatedCosts : campaign.costs}
          valuesAreEstimates={isCampaignDraft(campaign.status) || isCampaignPending(campaign.status)}
        />
      )}

      {/*  NOTE: DocuSign disabled */}
      {/* {campaign && campaign.status === 'PENDING_DOCUSIGN' && (
        <DashboardBubble
          rootClass={classes.instructionsBubble}
          backgroundColor={theme.palette.primary.light}
          icon={<InfoIcon htmlColor={theme.palette.secondary.main} />}
          content={(
            <>
              Your campaign has not started yet! Please sign the DocuSign contract sent to your email to start your campaign.
              <br />
              <br />
              {'Can\'t find the link? '}
              <ButtonBase onClick={onResendDocusign}>
                Click here
              </ButtonBase>
              {' to resend the DocuSign.'}
            </>
        )}
        />
      )} */}

      {campaign && campaign.status === 'PENDING_DATA' && (
        <DashboardBubble
          rootClass={classes.instructionsBubble}
          backgroundColor={theme.palette.primary.light}
          icon={<InfoIcon htmlColor={theme.palette.secondary.main} />}
          content={<span style={{ fontWeight: 500 }}>Your campaign will start shortly. Give us 24-72 hrs to crunch the data and prepare everything for you!</span>}
        />
      )}

      {campaign && campaign.status === 'PENDING_BILLING' && (
        <DashboardBubble
          rootClass={classes.instructionsBubble}
          backgroundColor={theme.palette.primary.light}
          icon={<InfoIcon htmlColor={theme.palette.secondary.main} />}
          // TODO: UPDATE THIS? INSTRUCTIONS ON WHAT TO DO IF FAILED? USER GETS AN EMAIL???
          content="We are awaiting the first invoice payment before starting your campaign."
        />
      )}

      {campaign && isCampaignCancelled(campaign.status) && (
        <DashboardBubble
          rootClass={classes.instructionsBubble}
          backgroundColor={theme.palette.primary.light}
          icon={<InfoIcon htmlColor={theme.palette.secondary.main} />}
          content="This campaign has been cancelled."
        />
      )}

      {tabContent}
    </div>
  );
};

export default CampaignDetailPage;
