import routes from 'agent-landing-routes';
import styles from 'assets/jss/material-dashboard-pro-react/layouts/agentLandingStyle';
import qs from 'qs';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AgentLandingFooter from 'components/AgentLanding/AgentLandingFooter/AgentLandingFooter';
import AgentLandingNavbar from 'components/AgentLanding/AgentLandingNavbar/AgentLandingNavbar';
import ContactModal from 'components/AgentLanding/Contact/ContactModal';
import AssessmentForm from 'components/AgentLanding/HomeEstimation/AssessmentForm';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import PublicRoute from 'components/AuthDataProvider/PublicRoute';
import LoadingDots from 'components/LoadingDots/LoadingDots';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';

import AgentLandingErrorPage from 'views/AgentLanding/AgentLandingErrorPage';

import { getAgentLandingHomeEstimationData, getAgentLandingPageData, getAgentLandingSampleData } from 'utils/api';
import { isAgentLandingPageSample } from 'utils/lib';

const useStyles = makeStyles(styles);

const AgentLanding = ({ location }) => {
  const classes = useStyles();
  const history = useHistory();
  const { currentAlert, currentModal } = useAlertContext();
  const { isLoadingIndicatorModalShowing } = useLoadingIndicatorContext();

  const { token: agentLandingPageToken, subject: eventId } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [agentLandingPageType, setAgentLandingPageType] = React.useState(null);
  const [isConsentGiven, setIsConsentGiven] = React.useState(null);
  const [agentData, setAgentData] = React.useState(null);

  /* For 'COMPARABLE_PROPERTIES' type */
  const [propertyData, setPropertyData] = React.useState(null);
  const [similarHomes, setSimilarHomes] = React.useState(null);
  /* For 'COMPARABLE_PROPERTIES' type */

  const redirectToErrorPage = () => history.push('/error');

  // Fetch home estimation data
  React.useEffect(() => {
    (async () => {
      if (!agentLandingPageToken) {
        redirectToErrorPage();
        return;
      }

      try {
        // If this is a sample page, get some example data to work with
        if (isAgentLandingPageSample(agentLandingPageToken)) {
          const data = await getAgentLandingSampleData(agentLandingPageToken, eventId);

          setAgentLandingPageType(data.agentLandingPageType);
          setIsConsentGiven(data.isConsentGiven);
          setAgentData(data.agentData);

          if (data.propertyData && data.similarHomes) {
            setPropertyData(data.propertyData);
            setSimilarHomes(data.similarHomes);
          } else {
            // If no comparable properties are found we fall back to the CONTACT_FORM agent landing page type
            setAgentLandingPageType('CONTACT_FORM');
          }

          return;
        }

        const landingPageData = await getAgentLandingPageData(agentLandingPageToken);
        setAgentLandingPageType(landingPageData.agentLandingPageType);
        setIsConsentGiven(landingPageData.isConsentGiven);
        setAgentData(landingPageData.agentData);

        if (landingPageData.agentLandingPageType === 'COMPARABLE_PROPERTIES') {
          const homeEstimationData = await getAgentLandingHomeEstimationData(agentLandingPageToken);

          if (homeEstimationData.similarHomes.length) {
            setPropertyData(homeEstimationData.propertyData);
            setSimilarHomes(homeEstimationData.similarHomes);
          } else {
            // If no comparable properties are found we fall back to the CONTACT_FORM agent landing page type
            setAgentLandingPageType('CONTACT_FORM');
          }
        }
      } catch (err) {
        // If the subject property can't be searched by benutech we fall back to the CONTACT_FORM agent landing page type
        if (err.response?.data?.error === 'Unable to get property details') {
          setAgentLandingPageType('CONTACT_FORM');
          return;
        }

        console.error(err);
        redirectToErrorPage();
      }
    })();
  }, []);

  const [assessmentModalOpen, setAssessmentModalOpen] = React.useState(false);
  const [isDownloadingReport, setIsDownloadingReport] = React.useState(false);

  const getRoutes = (routes2) => routes2.map((prop, key) => {
    if (prop.collapse) {
      return getRoutes(prop.views);
    }

    const RenderComponent = prop.render || prop.component;

    return (
      <Route
        exact={prop.exact}
        path={prop.layout + prop.path}
        render={() => (
          // Show loading indicator when fetching the initial home estimation data (which is the agent data)
          agentData ? (
            <RenderComponent
              agentLandingPageType={agentLandingPageType}
              agentLandingPageToken={agentLandingPageToken}
              isConsentGiven={isConsentGiven}
              setIsConsentGiven={setIsConsentGiven}
              setIsDownloadingReport={setIsDownloadingReport}
              agentData={agentData}
              propertyData={propertyData}
              similarHomes={similarHomes}
              eventId={eventId} // For agent's previews
            />
          ) : (<LoadingIndicator modal />)
        )}
        key={key}
      />
    );
  });

  return (
    <div className={classes.wrapper}>
      <AgentLandingNavbar
        agentLandingPageType={agentLandingPageType}
        agentData={agentData}
        openAssessmentModal={() => setAssessmentModalOpen(true)}
      />

      {currentAlert}
      {currentModal}
      {isLoadingIndicatorModalShowing ? (<LoadingIndicator modal />) : null}

      <div className={classes.content}>
        {isDownloadingReport && (
          <Dialog
            classes={{
              root: classes.downloadingReportModalRoot,
              paper: classes.downloadingReportModalPaper,
            }}
            open
            keepMounted
            maxWidth="sm"
          >
            <h3>Downloading Report</h3>
            <p>Your report is being generated, please wait...</p>
            <LoadingDots />
          </Dialog>
        )}

        {/* {assessmentModalOpen && <AssessmentForm onClose={() => setAssessmentModalOpen(false)} agentLandingPageToken={agentLandingPageToken} />} */}
        {assessmentModalOpen && <ContactModal onClose={() => setAssessmentModalOpen(false)} agentLandingPageToken={agentLandingPageToken} agentData={agentData} />}

        <Switch>
          {getRoutes(routes)}
          <Route path="*" component={() => (<PublicRoute component={AgentLandingErrorPage} />)} />
        </Switch>
      </div>
      <AgentLandingFooter />
    </div>
  );
};

export default AgentLanding;
