import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import DashboardSection from 'components/DashboardSection/DashboardSection';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import NotificationSettingsForm from 'components/NotificationSettingsForm/NotificationSettingsForm';

import { getSettings } from 'utils/api';
import { showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
});

const SettingsPage = () => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();

  const [availableNotificationSettings, setAvailableNotificationSettings] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      try {
        const { settings } = await getSettings('User', 'Notification');

        setAvailableNotificationSettings(settings);
      } catch (err) {
        console.error(err);
        showAPIErrorAlert(setCurrentAlert, err);
      }
    })();
  }, []);

  return (
    <div className={classes.root}>
      <DashboardSection sectionName="Notification Settings" />
      {availableNotificationSettings ? <NotificationSettingsForm settings={availableNotificationSettings} /> : <LoadingIndicator />}
    </div>
  );
};

export default SettingsPage;
