import { backdropContainer, backdropImage } from 'assets/jss/material-dashboard-pro-react';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Backdrop,
  Dialog,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';

import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import DashboardSection from 'components/DashboardSection/DashboardSection';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import CompanyDataForm from 'components/MarketingProfile/CompanyDataForm';
import ImagesForm from 'components/MarketingProfile/ImagesForm';
import MarketingMaterialsForm from 'components/MarketingProfile/MarketingMaterialsForm';
import PersonalDataForm from 'components/MarketingProfile/PersonalDataForm';
import SecondProfileDataForm from 'components/MarketingProfile/SecondProfileDataForm';
import Voicemails from 'components/MarketingProfile/Voicemails';
import SimpleModalHeader from 'components/SimpleModalHeader/SimpleModalHeader';
import UnsavedChangesWarning from 'components/UnsavedChangesWarning/UnsavedChangesWarning';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import { isMarketingProfileCompleted } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  backdropContainer,
  backdropImage,
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  readyToCreateContainer: {
    textAlign: 'center',

    '& > span': {
      fontSize: '123px',
      lineHeight: '150%',
    },

    '& > h3': {
      margin: '0 0 24px',
      fontSize: '33px',
      lineHeight: '150%',
      color: theme.palette.grayScale9.main,
    },
  },
}));

const tabs = [
  'General',
  'Voicemails',
  // 'CRM Pro'
];

const MarketingProfilePage = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const { userInfo } = useUserInfoContext();
  const { isBrokerageAdmin, isBrokerageAgent } = useAuthDataContext();

  const [imageOpen, setImageOpen] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState(location.state && location.state.activeTab ? location.state.activeTab : tabs[0]);

  const [isMarketingProfileIncomplete, setIsMarketingProfileIncomplete] = React.useState(null);
  const [showMarketingProfileCompleted, setShowMarketingProfileCompleted] = React.useState(false);

  const formRefs = [...Array(5).keys()].map(() => React.createRef());

  React.useEffect(() => {
    if (!userInfo) return;

    if (isMarketingProfileIncomplete === null) {
      // User info just loaded. Set the initial value for this.
      setIsMarketingProfileIncomplete(!isMarketingProfileCompleted(userInfo.agentInfo));
    } else if (isMarketingProfileIncomplete) {
      // User info was reloaded after a save. Check if its complete now so that we can show the create campaign alert.
      const isNowComplete = isMarketingProfileCompleted(userInfo.agentInfo);

      if (isNowComplete) {
        setIsMarketingProfileIncomplete(false);
      }
    }
  }, [userInfo]);

  if (!userInfo) {
    return (<LoadingIndicator />);
  }

  let tabContent;

  if (activeTab === 'General') {
    // NOTE: When adding new sections here, make sure to update the
    // isMarketingProfileDirty and isMarketingProfileCompleted functions

    tabContent = (
      <>
        <UnsavedChangesWarning formRefs={formRefs} />

        <ImagesForm ref={formRefs[0]} />

        {/* <DashboardSection sectionName="Print Colors" /> */}
        {/* TODO: WIP, see notes in PrintColorsForm */}

        <DashboardSection sectionName="Personal Info" />
        <PersonalDataForm ref={formRefs[1]} />

        <DashboardSection sectionName="Company Info" />
        <CompanyDataForm ref={formRefs[2]} />

        <DashboardSection sectionName="Agent Landing Page & Marketing Materials" />
        <MarketingMaterialsForm ref={formRefs[3]} />

        <DashboardSection sectionName="Second Profile for Marketing Materials" />
        <SecondProfileDataForm ref={formRefs[4]} />

        {/* NOTE: Make sure to change formRefs count if re-enabling this */}
        {/* <DashboardSection sectionName="Social Links" />
        <SocialLinksForm ref={formRefs[5]} /> */}
      </>
    );
  } else if (activeTab === 'Voicemails') {
    tabContent = <Voicemails />;
  }

  return (
    <div className={classes.root}>
      {showMarketingProfileCompleted && (
        <Dialog
          open
          scroll="body"
          keepMounted
          maxWidth="sm"
          onClose={() => setShowMarketingProfileCompleted(false)}
        >
          <SimpleModalHeader onClose={() => setShowMarketingProfileCompleted(false)} title="You did it!" />

          <div className={classes.readyToCreateContainer}>
            <span>🚀</span>
            <h3>Now you're ready to start farming!</h3>
            <Button
              round
              color="primary"
              onClick={() => history.push('/dashboard')}
            >
              Go to My Campaigns
            </Button>
          </div>
        </Dialog>
      )}

      {imageOpen ? (
        <Backdrop className={classes.backdropContainer} open onClick={() => setImageOpen(null)}>
          <img src={imageOpen} alt="" className={classes.backdropImage} />
        </Backdrop>
      ) : null}

      <DashboardSection
        sectionName="Marketing Profile"
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {tabContent}
    </div>
  );
};

export default MarketingProfilePage;
