import { Moment } from 'moment';
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';

import AcknowledgeModal from 'components/AcknowledgeModal/AcknowledgeModal';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import SimpleCampaignInfoCards from 'components/CampaignInfoCards/SimpleCampaignInfoCards';
import { useDataFilterContext } from 'components/DataMap/DataFilterProvider';
import DataMap from 'components/DataMap/DataMap';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';

import { getSimpleCampaignCounts } from 'utils/api';
import {
  calculateCampaignDates, numberWithCommas,
  supportEmail,
} from 'utils/lib';

import SimplePricingCards, { SimplePricingPlan } from '../SimplePricingCards';

const useStyles = makeStyles((theme) => ({
  map: {
    height: '95vh',
    maxHeight: '800px',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 20px',
  },
  infoCards: {
    margin: '0 0 50px',
  },
  nextButton: {
    float: 'right',
    marginTop: '30px',
    marginBottom: '20px',
  },
}));

type Props = {
  handleNext: () => void;
  selectedPlan: SimplePricingPlan | undefined;
  setSelectedPlan: React.Dispatch<React.SetStateAction<SimplePricingPlan | undefined>>;
  apnsTotal: number | undefined;
  setApnsTotal: React.Dispatch<React.SetStateAction<number | undefined>>;
  apnsWithPropensityToSell: number | undefined;
  setApnsWithPropensityToSell: React.Dispatch<React.SetStateAction<number | undefined>>;
  setZipCode: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export default ({
  handleNext, selectedPlan, setSelectedPlan, apnsTotal, setApnsTotal, apnsWithPropensityToSell, setApnsWithPropensityToSell, setZipCode,
}: Props) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext() as any;
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext() as any;
  const {
    availableEventTypes, selectedEventType, setSelectedEventTypeByName,
  } = useDataFilterContext() as any;

  const { user, isAnyAgent } = useAuthDataContext();

  const dataSelectionOverlayRef = React.useRef<any>(null);

  const [campaignDates, setCampaignDates] = React.useState<[Moment, Moment] | null>(null);
  const [showFetchCountsErrorModal, setShowFetchCountsErrorModal] = React.useState(false);

  React.useEffect(() => {
    if (!availableEventTypes) return;

    // Always full farm
    setSelectedEventTypeByName('Full Farm');
  }, [availableEventTypes]);

  React.useEffect(() => {
    if (!selectedEventType) return;

    const { campaignStartDate, campaignEndDate } = calculateCampaignDates(selectedEventType);
    setCampaignDates([campaignStartDate, campaignEndDate]);
  }, [selectedEventType]);

  if (!selectedEventType || !campaignDates) return (<LoadingIndicator />);

  const onFetchDataCount = async (selectedZipcodes: string[], selectionPolygons: any[]) => {
    showLoadingIndicatorModal();

    try {
      const zipCode = selectedZipcodes[0];

      const counts = await getSimpleCampaignCounts(zipCode);

      setCurrentAlert('success', `We found ${numberWithCommas(counts.apnsWithPropensityToSell)} nearby homes with a high propensity to sell out of ${numberWithCommas(counts.apnsTotal)} total homes`, 15000);

      setZipCode(zipCode);
      setApnsTotal(counts.apnsTotal);

      // Sanity check in case there are 0 properties in the area. Don't let
      // user proceed with selecting a plan in this case.
      if (counts.apnsWithPropensityToSell > 0) {
        setApnsWithPropensityToSell(counts.apnsWithPropensityToSell);
      }
    } catch (err) {
      console.error(err);

      dataSelectionOverlayRef.current.allowRefetchCounts();
      setShowFetchCountsErrorModal(true);
    }

    hideLoadingIndicatorModal();
  };

  const onNext = () => {
    if (user && !isAnyAgent) {
      setCurrentAlert('warning', 'You can not proceed on a non-agent account.', 15000);
      return;
    }

    handleNext();
  };

  return (
    <>
      {showFetchCountsErrorModal && (
        <AcknowledgeModal
          title="Whoa! We're popular right now!"
          message={(
            <>
              Our system is now under heavy usage. Please retry your query again. You might need to retry it 3 or 4 times for it to work. Or come back in a few hours! If you have any issues, please contact us at:
              {' '}
              <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
            </>
                  )}
          onClose={() => setShowFetchCountsErrorModal(false)}
        />
      )}

      <div className={classes.map}>
        <DataMap
          dataSelectionOverlayRef={dataSelectionOverlayRef}
          allowDataSelection
          disableFilters
          inputLabelText="Enter the zip code to target for your farm"
          maxNumberOfZipcodes={1}
          enableShapeDrawing={false}
          generateCostsButtonText="Calculate Homes"
          onFetchDataCountCustom={onFetchDataCount}
        />
      </div>

      <div className={classes.infoCards}>
        <SimpleCampaignInfoCards
          startDate={campaignDates[0]}
          campaignLengthInMonths={selectedEventType.campaignLengthInMonths}
          apnsTotal={apnsTotal}
          apnsWithPropensityToSell={apnsWithPropensityToSell}
        />
      </div>

      {apnsWithPropensityToSell !== undefined && (
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <SimplePricingCards handleSelectPlan={(plan) => setSelectedPlan(plan)} />
        </div>
      )}

      {selectedPlan && (
        <Button
          round
          color="primary"
          className={classes.nextButton}
          onClick={() => onNext()}
        >
          Next
        </Button>
      )}
    </>
  );
};
