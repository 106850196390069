import cx from 'classnames';
import { useMyBrokerage } from 'data/brokerage';
import { useCampaigns } from 'data/campaign';
import React from 'react';
import { Link } from 'react-router-dom';

import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';

import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import CampaignsTable from 'components/CampaignsTable/CampaignsTable';
import DashboardSection from 'components/DashboardSection/DashboardSection';
import DataMap from 'components/DataMap/DataMap';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import SelectCampaignEventTypeModal from 'components/SelectCampaignEventTypeModal/SelectCampaignEventTypeModal';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import { isMarketingProfileCompleted } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  userCampaignsSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // minHeight: '300px',
    // maxHeight: '800px',
  },
  sectionSpacer: {
    height: '20px',
  },
  userFarmingAreaSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '500px',
    maxHeight: '800px',
  },
  campaignsTable: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    overflowX: 'auto',
    overflowY: 'auto',
  },
  instructionsText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '36px',
    color: theme.palette.black.main,
  },
  marketingProfileInstructions: {
    marginBottom: '60px',

    '& > a': {
      whiteSpace: 'nowrap',
      font: 'inherit',
      padding: '6px 10px',
      color: theme.palette.orange.main,
      backgroundColor: '#FDE8E4',
      borderRadius: '8px',
      textDecoration: 'underline',
    },

    '& > span': {
      color: theme.palette.error.main,
    },
  },
  createCampaignInstructions: {
    '& > button': {
      font: 'inherit',
      padding: '6px 10px',
      color: '#116942',
      backgroundColor: '#DEF2E9',
      borderRadius: '8px',
      textDecoration: 'underline',
    },
  },
  addBillingInfoInstructions: {
    marginBottom: '60px',

    '& > a': {
      font: 'inherit',
      padding: '6px 10px',
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.light,
      borderRadius: '8px',
      textDecoration: 'underline',
    },
  },
  addBrokerageAgentsInstructions: {
    '& > a': {
      font: 'inherit',
      padding: '6px 10px',
      color: '#116942',
      backgroundColor: '#DEF2E9',
      borderRadius: '8px',
      textDecoration: 'underline',
    },
  },
}));

const CampaignDashboardPage = () => {
  const classes = useStyles();
  const { userInfo } = useUserInfoContext();
  const { isBrokerageAdmin } = useAuthDataContext();

  const campaignStatusesToFetch = isBrokerageAdmin
    ? ['PENDING_DOCUSIGN', 'PENDING_DATA', 'PENDING_BILLING', 'ACTIVE', 'PAUSED', 'COMPLETED', 'CANCELLED']
    : ['DRAFT', 'PENDING_DOCUSIGN', 'PENDING_DATA', 'PENDING_BILLING', 'ACTIVE', 'PAUSED', 'COMPLETED', 'CANCELLED'];

  const {
    campaigns, isLoadingCampaigns, isLoadingCampaignsError,
  } = useCampaigns(null, campaignStatusesToFetch);

  const { brokerage, isLoadingBrokerage } = useMyBrokerage(isBrokerageAdmin);

  const [outlineZipcodes, setOutlineZipcodes] = React.useState([]);
  const [allCampaignSubscribedEvents, setAllCampaignSubscribedEvents] = React.useState(null);

  const [showSelectCampaignEventTypeModal, setShowSelectCampaignEventTypeModal] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (allCampaignSubscribedEvents) return; // Data already loaded

      if (!userInfo || isLoadingCampaigns) return;

      // Only show farm area for pending, active, or paused campaigns
      const campaignsToShow = campaigns.filter(({ status }) => ['PENDING_DOCUSIGN', 'PENDING_DATA', 'PENDING_BILLING', 'ACTIVE', 'PAUSED'].includes(status));

      if (campaignsToShow && campaignsToShow.length > 0) {
        // Find all the zipcodes there is event data in so we can highlight those
        const zipcodesToHighlight = [];
        const allSubscribedEvents = [];

        for (let i = 0; i < campaignsToShow.length; i++) {
          const campaign = campaignsToShow[i];

          allSubscribedEvents.push(...campaign.subscribedEvents);

          campaign.subscribedEvents.forEach((eventData) => {
            const { _id, siteAddress: { zipcode } } = eventData;

            if (!zipcodesToHighlight.includes(zipcode)) {
              zipcodesToHighlight.push(zipcode);
            }

            // TODO: REMOVE THIS???
            // // If there's an event override for this, set it in the event data object
            // // to later use in the event data hover popup window when displaying property information
            // const [existingEventOverride] = campaign.eventOverrides.filter((o) => o.event === _id);

            // if (existingEventOverride) {
            //   // eslint-disable-next-line no-param-reassign
            //   eventData.existingEventOverride = existingEventOverride;
            // }
          });
        }

        setAllCampaignSubscribedEvents(allSubscribedEvents);
        setOutlineZipcodes(zipcodesToHighlight);
      }
    })();
  }, [userInfo, isLoadingCampaigns]);

  const onCreateCampaign = async () => {
    setShowSelectCampaignEventTypeModal(true);
  };

  const createCampaignButton = (
    <Button
      color="primary"
      round
      onClick={onCreateCampaign}
    >
      + Create New Campaign
    </Button>
  );

  let campaignsTable;

  if (isLoadingCampaigns || !userInfo || (isBrokerageAdmin && isLoadingBrokerage)) {
    campaignsTable = <LoadingIndicator />;
  } else if (campaigns.length === 0) {
    if (isBrokerageAdmin) {
      campaignsTable = (
        <>
          <p className={cx(classes.instructionsText, classes.marketingProfileInstructions)}>
            Hi There 👋 - You will be able to see your agents' campaigns here when they create them!
            {brokerage.branches.length === 0 && (
              <>
                <br />
                <br />
                Let's get started with
                {' '}
                <Link to="/dashboard/brokerage-branch-marketing-profile">
                  creating your first branch.
                </Link>
              </>
            ) }
          </p>
          {/* <p className={cx(classes.instructionsText, classes.addBillingInfoInstructions)}>
            Then &gt;
            {' '}
            <Link to="/dashboard/brokerage-billing">
              Set up
            </Link>
            {' '}
            your brokerage's subscription plan.
          </p> */}
          <p className={cx(classes.instructionsText, classes.addBrokerageAgentsInstructions)}>
            Next &gt;
            {' '}
            <Link to="/dashboard/my-brokerage">
              Invite your agents
            </Link>
            {' '}
            to Harvist so they can start farming!
          </p>
        </>
      );
    } else if (isMarketingProfileCompleted(userInfo.agentInfo)) {
      campaignsTable = (
        <p className={cx(classes.instructionsText, classes.createCampaignInstructions)}>
          Let's get started with a
          {' '}
          <ButtonBase onClick={onCreateCampaign}>
            NEW Campaign!
          </ButtonBase>
        </p>
      );
    } else {
      campaignsTable = (
        <>
          <p className={cx(classes.instructionsText, classes.marketingProfileInstructions)}>
            Hi There 👋 - Let's get started with your
            {' '}
            <Link to="/dashboard/marketing-profile">
              Marketing Profile.
            </Link>
          </p>
          <p className={cx(classes.instructionsText, classes.createCampaignInstructions)}>
            Next &gt; Start by creating a
            {' '}
            <ButtonBase onClick={onCreateCampaign}>
              NEW Campaign!
            </ButtonBase>
          </p>
        </>
      );
    }
  } else {
    campaignsTable = (
      <CampaignsTable campaigns={campaigns} tableProps={{ showFilters: false }} />
    );
  }

  return (
    <div className={classes.root}>
      {showSelectCampaignEventTypeModal && <SelectCampaignEventTypeModal onClose={() => setShowSelectCampaignEventTypeModal(false)} />}

      <div className={classes.userCampaignsSection}>
        <DashboardSection
          sectionName="Your Campaigns Dashboard"
          buttons={userInfo ? [createCampaignButton] : []}
        />
        <div className={classes.campaignsTable}>
          {campaignsTable}
        </div>
      </div>
      <div className={classes.sectionSpacer} />
      {/* Only show if the user has campaigns */}
      {campaigns && campaigns.length > 0 && (
        <div className={classes.userFarmingAreaSection}>
          <DashboardSection
            sectionName="Your Farming Area"
          />
          {userInfo ? (
            <DataMap
              highlightZipcodes={outlineZipcodes}
              highlightEventData={allCampaignSubscribedEvents || null}
              highlightEventDataShowHoverPopup
              showOnlyHighlightedZipcodes
            />
          ) : <LoadingIndicator />}
        </div>
      )}
    </div>
  );
};

export default CampaignDashboardPage;
